<template>
      <div>
            <router-view></router-view>
            <cFooter></cFooter>
      </div>
</template>

<script>
      import cFooter from '../component/footer';

      export default {
            name: "atc",
            components: {
                  cFooter,
            },
      }
</script>

<style lang="scss" scoped>

</style>